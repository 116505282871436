import React from 'react'
import { FaMastodon, FaGoodreads, FaLinkedinIn, FaTwitter, FaYoutube, FaInstagram } from 'react-icons/fa';

const navigation = {
	main: [
	  { name: 'About', href: '/about' },
	  { name: 'Contact Us', href: '/contact-us' },
	  { name: 'Autism-101', href: 'https://autism-101.com' },
	  { name: 'Privacy', href: '/privacy' },
	  { name: 'Terms', href: '/terms' },
	],
	social: [
		{
			name: 'Twitter(X)',
			href: 'https://twitter.com/Autism1o1',
			icon: (props) => <FaTwitter {...props} />
		},
		{
			name: 'Mastodon',
			href: 'https://mstdn.social/@autism101',
			icon: (props) => <FaMastodon {...props} />
		},
		{
			name: 'Instagram',
			href: 'https://www.instagram.com/autism_1_0_1/',
			icon: (props) => <FaInstagram {...props} />
		},
		{
			name: 'Goodreads',
			href: 'https://www.goodreads.com/user/show/147931757-autism-101',
			icon: (props) => <FaGoodreads {...props} />
		},
		{
			name: 'LinkedIn',
			href: 'https://www.linkedin.com/in/autism101/',
			icon: (props) => <FaLinkedinIn {...props} />
		},
		{
			name: 'Youtube',
			href: 'https://www.youtube.com/@autism101/playlists',
			icon: (props) => <FaYoutube {...props} /> // Using a generic link for Linktree
		},
	]
	      
      }

      export default function Footer() {
	return (
	  <footer className="bg-black">
	    <div className="mx-auto max-w-7xl overflow-hidden px-6 py-10 sm:py-24 lg:px-8">
				<nav className="grid grid-cols-2 gap-2 sm:flex sm:justify-center sm:space-x-4 pb-4" aria-label="Footer">
						{navigation.main.map((item) => (
								<div key={item.name} className="text-center sm:text-left">
										<a href={item.href} className="text-m md:text-xl leading-6 text-gray-600 hover:text-yellow-500">
												{item.name}
										</a>
								</div>
						))}
				</nav>
	      <div className="mt-6 flex justify-center space-x-10">
					{navigation.social.map((item) => (
						<a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500 sm:text-sm">
							<span className="sr-only">{item.name}</span>
							<item.icon className="h-6 w-6" aria-hidden="true" />
						</a>
					))}
	      </div>
				<div className="flex items-center justify-center mt-10">
					<p className='text-xs leading-5 text-gray-500 md:text-lg'>&copy;2024</p>
					<p><img src="/aitm-logo.svg" alt="Autism in the Media" className="h-3 w-auto ml-2 mb-1 md:h-5 md:ml-3" /></p>
					<p><a href="https://themoviedb.org"><img src="/tmdb.svg" alt="The Movie DB" className="h-3 w-auto ml-2 md:h-4 md:ml-3" /></a></p>
					<p><a href="https://autism-101.com"><img src="/autism-101.svg" alt="Autism-101" className="h-3 w-auto ml-2 md:h-6 md:ml-3" /></a></p>
				</div>
	    </div>
	  </footer>
	)
      }
      