import React, { useState, useEffect } from 'react';
import SavedShows from '../components/SavedShows';
import { deleteAccount } from '../firebase';
import { useError } from '../contexts/ErrorContext';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

const Account = () => {
	const navigate = useNavigate();
	const { error, setError, clearError } = useError();
  const [userDetails, setUserDetails] = useState(null);

  const handleDeleteAccount = () => {
    const confirmed = window.confirm("Are you sure you want to delete your account? This cannot be undone.");
  
    if (confirmed) {
      deleteAccount()
        .then(() => {
          // console.log("Account deleted successfully.");
          navigate("/");  // Redirect user to the home or login page
        })
        .catch(error => {
          console.error("Error deleting account:", error);
          setError("Error deleting account: " + error.message);
        });
    }
  };

  useEffect(() => {
    // Observer for authentication changes
    const authUnsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        const userDocRef = doc(db, "users", user.uid);
  
        // Set up the listener using the onSnapshot method on the document reference
        const dbUnsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            setUserDetails(docSnapshot.data());
          } else {
            console.error("No such document!");
          }
        }, (error) => {
            console.error("Error fetching user data: ", error);
        });
  
        // Return cleanup function for Firestore listener
        return () => dbUnsubscribe();
      } else {
        // User is signed out.
        setUserDetails(null);
      }
    });
  
    // Return cleanup function for Auth listener
    return () => authUnsubscribe();
  }, []);
  

  return (
    <>
      <div className='w-full text-white'>
        <img
          className='w-full h-[250px] md:h-[300px] lg:h-[400px] object-cover'
          src='aitm-background.jpg'
          alt='/'
        />
        <div className='bg-black/60 fixed top-0 left-0 w-full h-[310px] md:h-[550px] lg:h-[526px]'></div>
        <div className='absolute top-[10%] md:top-[10%] lg:top-[15%] p-4 md:p-8'>
          <h1 className='pb-4 text-3xl md:text-5xl font-bold'>Account</h1>
          {userDetails && (
            <>
              <p>Email: {userDetails.email}</p>
              <p>Email Verified: {auth.currentUser && auth.currentUser?.emailVerified ? "Yes" : "No"}</p>
            </>
          )}
          <p className='mt-5'>
          <Link onClick={handleDeleteAccount} className="mr-4 underline">
              Delete Account
          </Link>
          </p>
        </div>
      </div>
      <SavedShows />
    </>
  );
};

export default Account;
