import React, { useState, useEffect } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { updateDoc, doc, onSnapshot } from 'firebase/firestore';
import { AiOutlineClose } from 'react-icons/ai';

const SavedShows = () => {
  const [media, setMedia] = useState([]);
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);

  const slideLeft = () => {
    var slider = document.getElementById('slider');
    slider.scrollLeft = slider.scrollLeft - 500;
  };
  const slideRight = () => {
    var slider = document.getElementById('slider');
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  useEffect(() => {
    if (user?.uid) {
        const unsubscribe = onSnapshot(doc(db, 'users', user?.uid), (docSnapshot) => {
            setMedia(docSnapshot.data()?.savedShows || []);
            setLoading(false); // Setting loading to false after fetching data
        });

        // Cleanup function for the onSnapshot listener
        return () => unsubscribe();
    } else {
        setLoading(false); // If there's no user UID, we also set loading to false
    }
}, [user?.uid]);

  // const userDoc = user && doc(db, 'users', user?.uid);
  const userDoc = user?.uid ? doc(db, 'users', user.uid) : null;
  
  const deleteShow = async (passedID) => {
    try {
      const result = media.filter((item) => item?.id !== passedID);
      if (userDoc) {
        await updateDoc(userDoc, {
          savedShows: result
        });
      }
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <>
      {loading ? (
          <div>Loading...</div>
      ) : (
        <>
          <h2 className='text-white font-bold md:text-xl p-4'>My Favorites</h2>
          <div className='relative flex items-center group'>
            <MdChevronLeft
              onClick={slideLeft}
              className='bg-white left-0 rounded-full absolute opacity-50 hover:opacity-100 cursor-pointer z-10 hidden group-hover:block'
              size={40}
            />
            <div
              id={'slider'}
              className='w-full h-full overflow-x-scroll whitespace-nowrap scroll-smooth scrollbar-hide relative'
            >
              {Array.isArray(media) && media.map((item) => (
                <div
                  key={item?.id}
                  className='w-[160px] sm:w-[200px] md:w-[240px] lg:w-[280px] inline-block cursor-pointer relative p-2'
                >
                  <img
                    className='w-full h-auto block'
                    src={`https://image.tmdb.org/t/p/w500/${item?.img}`}
                    alt={item?.title}
                  />
                  <div className='absolute top-0 left-0 w-full h-full hover:bg-black/80 opacity-0 hover:opacity-100 text-white'>
                    <p className='white-space-normal text-xs md:text-sm font-bold flex justify-center items-center h-full text-center'>
                      {item?.title}
                    </p>
                    <p onClick={()=> deleteShow(item?.id)} className='absolute text-gray-300 top-4 right-4'><AiOutlineClose /></p>
                  </div>
                </div>
              ))}
            </div>
            <MdChevronRight
              onClick={slideRight}
              className='bg-white right-0 rounded-full absolute opacity-50 hover:opacity-100 cursor-pointer z-10 hidden group-hover:block'
              size={40}
            />
          </div>
        </>
      )}
    </>
  );
};

export default SavedShows;
