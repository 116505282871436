import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { signOutUser } from '../firebase';
import { useError } from '../contexts/ErrorContext';
import api from '../services/api';
import { movieRequests, tvShowRequests } from '../Requests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setMenuOpen] = useState(false); // State for menu toggle
  const { setError } = useError();

  // Search constants
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const searchRef = useRef(null);
  const searchContainerRef = useRef(null);
  const [searchTimeout, setSearchTimeout] = useState(null);
  const toggleSearchVisibility = () => {
    setIsSearchVisible(!isSearchVisible);
  }

  const handleClickOutside = (event) => {
    if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
      setSearchResults([]);
      setIsSearchVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchTerm === '') {
      setSearchResults([]);
    }
  }, [searchTerm]);

  const handleSearch = useCallback(async () => {
    // Initial setup remains the same
    const localMovies = JSON.parse(localStorage.getItem('allitems-movies')) || [];
    const localTVShows = JSON.parse(localStorage.getItem('allitems-tv')) || [];
    
    // First, search locally stored items
    let movieMatches = localMovies.filter(movie => movie.title.toLowerCase().includes(searchTerm.toLowerCase()));
    let tvMatches = localTVShows.filter(tv => tv.name.toLowerCase().includes(searchTerm.toLowerCase()));
  
    // If no local matches were found, fetch data from the API
    if (movieMatches.length === 0 && tvMatches.length === 0) {
      let fetchedMovieItems = [];
      let fetchedTVItems = [];
      let currentMoviePage = 1;
      let currentTVPage = 1;
      let moreMoviePagesAvailable = true;
      let moreTVPagesAvailable = true;
  
      const fetchAllMoviesURL = movieRequests.all();
      const fetchAllTVShowsURL = tvShowRequests.all();
  
      // Fetching movies
      while (moreMoviePagesAvailable) {
        try {
          const response = await api.get(`${fetchAllMoviesURL}&page=${currentMoviePage}`);
          const { items, item_count } = response.data;
  
          fetchedMovieItems = [...fetchedMovieItems, ...items];
          moreMoviePagesAvailable = fetchedMovieItems.length < item_count;
          currentMoviePage += 1;
        } catch (error) {
          console.error("An error occurred:", error);
          break; // Exit the loop in case of an error
        }
      }
  
      // Fetching TV shows
      while (moreTVPagesAvailable) {
        try {
          const response = await api.get(`${fetchAllTVShowsURL}&page=${currentTVPage}`);
          const { items, item_count } = response.data;
  
          fetchedTVItems = [...fetchedTVItems, ...items];
          moreTVPagesAvailable = fetchedTVItems.length < item_count;
          currentTVPage += 1;
        } catch (error) {
          console.error("An error occurred:", error);
          break; // Exit the loop in case of an error
        }
      }
  
      // After fetching all items, then filter them based on the search term
      movieMatches = fetchedMovieItems.filter(movie => movie.title.toLowerCase().includes(searchTerm.toLowerCase()));
      tvMatches = fetchedTVItems.filter(tv => tv.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }
  
    // Setting the search results, whether they come from local storage or the API
    setSearchResults([...movieMatches, ...tvMatches]);
  }, [searchTerm]);
  

  useEffect(() => {
    if (searchTerm) {
      // Set a timeout to delay the search until user has stopped typing for 300ms
      const timeout = setTimeout(() => {
        handleSearch();
      }, 300);
  
      // Clear the timeout on component unmount or when searchTerm changes
      return () => clearTimeout(timeout);
    }
  }, [searchTerm, handleSearch ]);

  const handleLogout = async (e) => {
    e.preventDefault();

    try {
      await signOutUser();
      navigate('/');
    } catch (error) {
			// console.log("Failed to login.");
      setError("Failed to logout."); // Or translate the error message to be more user-friendly
    }
  };

  const handleLinkClick = (path) => {
    setMenuOpen(false); // Close the menu
    navigate(path);    // Navigate to the selected path
  }

  const handleResultClick = () => {
    setSearchResults('');
    setIsSearchVisible(false);
  };
  
  useEffect(() => {
    const resultLinks = document.querySelectorAll('#search-results a');
    resultLinks.forEach(link => {
      link.addEventListener('click', handleResultClick);
    });
  
    // Cleanup: remove event listeners when component is unmounted or when searchResults change
    return () => {
      resultLinks.forEach(link => {
        link.removeEventListener('click', handleResultClick);
      });
    };
  }, [searchResults]);


  return (
    <div className='relative z-[100] w-full'>
      <div className='flex items-center justify-between p-2 md:p-8 lg:p-10 w-full'>
        <Link to='/'>
          <p className='text-red-600 text-2xl md:text-4xl font-bold cursor-pointer md:block hidden'>
            <img src="/aitm-logo.svg" alt="Autism in the Media" className="w-32 h-auto sm:w-40 sm:h-auto md:w-48 md:h-auto" />
          </p>
          <h1 className='text-red-600 text-3xl md:text-4xl font-bold cursor-pointer md:hidden block'>
            aitm
          </h1>
        </Link>

        {/* Hamburger Icon - Only visible on mobile */}
        <button 
          onClick={() => setMenuOpen(!isMenuOpen)}
          className='md:hidden flex flex-col space-y-2'
        >
          <div className='w-6 h-0.5 bg-red-600'></div>
          <div className='w-6 h-0.5 bg-red-600'></div>
          <div className='w-6 h-0.5 bg-red-600'></div>
        </button>

        {/* Desktop Nav - Hidden on mobile */}
        <div className='hidden md:flex'>
          {user?.email ? (
            <div ref={searchContainerRef}>


              <div onClick={toggleSearchVisibility} className="inline cursor-pointer pr-4">
                <FontAwesomeIcon icon={faSearch} className="text-white" />
              </div>
              {isSearchVisible && (
                <div className="inline relative pr-4">
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={handleSearch}
                    placeholder="Search..."
                    className="p-2 rounded border"
                  />
                  {searchResults.length > 0 && (
                    <div id="search-results" className="absolute top-full left-0 mt-2 bg-white border border-gray-300 rounded shadow-lg">
                      {searchResults.map(item => (
                        <Link
                          key={item.id}
                          to={`/overview/${item.media_type}/${item.id}`}
                          className="block p-2 hover:bg-gray-200"
                        >
                          {item.title || item.name}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              )}


              <Link to='/about'>
              <button className={`text-white pr-4 hover:text-yellow-500 ${location.pathname === '/about' ? 'text-yellow-500' : ''}`}>
                About
              </button>
              </Link>
              <Link to='/contact-us'>
                <button className={`text-white pr-4 hover:text-yellow-500 ${location.pathname === '/contact-us' ? 'text-yellow-500' : ''}`}>Contact Us</button>
              </Link>
              <Link to='/account'>
                <button className={`text-white pr-4 hover:text-yellow-500 ${location.pathname === '/account' ? 'text-yellow-500' : ''}`}>Account</button>
              </Link>
              <button
                onClick={handleLogout}
                className='bg-red-600 px-6 py-2 rounded cursor-pointer text-white'
              >
                Logout
              </button>
            </div>
          ) : (
            <div ref={searchContainerRef}>

              <div onClick={toggleSearchVisibility} className="inline cursor-pointer pr-4">
                <FontAwesomeIcon icon={faSearch} className="text-white" />
              </div>
              {isSearchVisible && (
                <div className="inline relative pr-4">
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={handleSearch}
                    placeholder="Search..."
                    className="p-2 rounded border"
                  />
                  {searchResults.length > 0 && (
                    <div id="search-results" className="absolute top-full left-0 mt-2 bg-white border border-gray-300 rounded shadow-lg">
                      {searchResults.map(item => (
                        <Link
                          key={item.id}
                          to={`/overview/${item.media_type}/${item.id}`}
                          className="block p-2 hover:bg-gray-200"
                        >
                          {item.title || item.name}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              )}



              <Link to='/about'>
              <button className={`text-white pr-4 hover:text-yellow-500 ${location.pathname === '/about' ? 'text-yellow-500' : ''}`}>
                About
              </button>
              </Link>
              <Link to='/contact-us'>
                <button className={`text-white pr-4 hover:text-yellow-500 ${location.pathname === '/contact-us' ? 'text-yellow-500' : ''}`}>Contact Us</button>
              </Link>
              <Link to='/signin'>
                <button className={`text-white pr-4 hover:text-yellow-500 ${location.pathname === '/signin' ? 'text-yellow-500' : ''}`}>Sign In</button>
              </Link>
              <Link to='/signup'>
                <button className='bg-red-600 px-6 py-2 rounded cursor-pointer text-white'>
                  Sign Up
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>

      {/* Mobile Navigation */}
      {isMenuOpen && (
        <div className="flex flex-col space-y-4 mt-0 p-5 bg-gray-800 text-white md:hidden">
          {user?.email ? (
            <div ref={searchContainerRef} className="flex flex-col space-y-2 items-end">
                            
                            
              <div onClick={toggleSearchVisibility} className="inline cursor-pointer pr-4">
                <FontAwesomeIcon icon={faSearch} className="text-white" />
              </div>
              {isSearchVisible && (
                <div className="inline relative pr-4">
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={handleSearch}
                    placeholder="Search..."
                    className="p-2 rounded border"
                  />
                  {searchResults.length > 0 && (
                    <div id="search-results" className="absolute top-full left-0 mt-2 bg-white border border-gray-300 rounded shadow-lg">
                      {searchResults.map(item => (
                        <Link
                          key={item.id}
                          to={`/overview/${item.media_type}/${item.id}`}
                          className="block p-2 hover:bg-gray-200"
                        >
                          {item.title || item.name}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              )}
              <button className={`text-right ${location.pathname === '/about' ? 'text-yellow-500' : ''}`} onClick={() => handleLinkClick('/about')}>About</button>
              <button className={`text-right ${location.pathname === '/account' ? 'text-yellow-500' : ''}`} onClick={() => handleLinkClick('/account')}>Account</button>
              <button onClick={handleLogout}>Logout</button>
            </div>
          ) : (
            <div ref={searchContainerRef} className="flex flex-col space-y-2 items-end">
              
              <div onClick={toggleSearchVisibility} className="inline cursor-pointer pr-4">
                <FontAwesomeIcon icon={faSearch} className="text-white" />
              </div>
              {isSearchVisible && (
                <div className="text-black inline relative pr-4">
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={handleSearch}
                    placeholder="Search..."
                    className="p-2 rounded border"
                  />
                  {searchResults.length > 0 && (
                    <div id="search-results" className="absolute top-full left-0 mt-2 bg-white border border-gray-300 rounded shadow-lg">
                      {searchResults.map(item => (
                        <Link
                          key={item.id}
                          to={`/overview/${item.media_type}/${item.id}`}
                          className="block p-2 hover:bg-gray-200"
                        >
                          {item.title || item.name}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              )}
              <button className={`text-right ${location.pathname === '/about' ? 'text-yellow-500' : ''}`} onClick={() => handleLinkClick('/about')}>About</button>
              <button className={`text-right ${location.pathname === '/contact-us' ? 'text-yellow-500' : ''}`} onClick={() => handleLinkClick('/contact-us')}>Contact Us</button>
              <button className={`text-right ${location.pathname === '/signin' ? 'text-yellow-500' : ''}`} onClick={() => handleLinkClick('/signin')}>Sign In</button>
              <button className={`text-right ${location.pathname === '/signup' ? 'text-yellow-500' : ''}`} onClick={() => handleLinkClick('/signup')}>Sign Up</button>
            </div>
          )}
        </div>
      )}



    </div>
  );
};

export default Navbar;