// ContactUs.jsx
import React, { useState } from 'react';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';  
import { getAuth } from 'firebase/auth';
import { app } from '../firebase';
import { recaptchaConfig } from '../firebase';
import SEO from '../components/SEO';
import ReCAPTCHA from "react-google-recaptcha";
import { useError } from '../contexts/ErrorContext';
import AlertBox from '../components/AlertBox';

function ContactUs() {
		const { error, setError } = useError();
		const [alert, setAlert] = useState(null);
		const [recaptchaValue, setRecaptchaValue] = useState(null);

    const [formData, setFormData] = useState({
        message: '',
        themoviedbURL: '',
        email: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

		const handleSubmit = async (e) => {
			e.preventDefault();

			if (!recaptchaValue) {
        setAlert("Please verify the reCAPTCHA.");
        return;
    	}
	
			const db = getFirestore(app);
			const auth = getAuth(app);
	
			const submissionData = {
					...formData,
					uid: auth.currentUser?.uid || null,  // Get the UID if the user is logged in, else store null
					formType: 'ContactUs',
					timestamp: serverTimestamp()  // To know when the submission was made
			};
	
			try {
					await addDoc(collection(db, "submissions"), submissionData);
					// console.log("Document written with ID:", docRef.id);
	
					setAlert('Thank you for your submission!');
					setFormData({ message: '', themoviedbURL: '', email: '' });

			} catch (error) {
					console.error('Error submitting form:', error);
					setError('There was an error. Please try again.');
			}
	};
	
	const clearInfo = () => {
		setAlert(null);
	};

	const clearErrorBox = () => {
		setError(null);
		setAlert(null);
	};

    return (
			<>
			<div className='w-full min-h-screen relative'>
					<SEO
          title='Submit Movies & TV Shows | Autism In The Media'
          description='Help diversify autism representation in media by submitting movies or TV shows featuring autistic characters. Share your insights or leave comments to foster better representation.'
          keywords='Submit Movies, Submit TV Shows, Autism Representation, Contact Autism In The Media, Movie Submission, TV Show Submission, Autism Media Diversity, Autistic Characters Submission'
          type='website' />
					<img
						className='hidden sm:block absolute inset-0 w-full h-full object-cover z-0'
						src='aitm-background.jpg'
						alt='/'
					/>
					<div className='bg-black/60 absolute inset-0 z-10'></div>
					<div className='flex items-start justify-center mt-0 min-h-screen px-4 py-0 md:py-0 lg:py-0 xlg:py-8 z-50 relative'>
            <div className='max-w-[450px] h-[580px] px-10 mx-auto bg-black/75 text-white'>
                <div className='max-w-[320px] mx-auto py-4 md:py-8 lg:py-4'>
											<h1 className='text-3xl font-bold'>Contact Us</h1>
							<p className='pt-4'>If you'd like to suggest a movie or TV show, please find it on <a href="https://www.themoviedb.org" target="_blank" rel="noopener noreferrer" className="text-blue-500">The Movie DB</a> and paste the URL below:</p>
							<AlertBox type="error" title="Error" message={error} clearAlert={clearErrorBox} />
							<AlertBox type="info" title="Information" message={alert} color="#3498db" clearAlert={clearInfo} />
							<form onSubmit={handleSubmit} className='w-full flex flex-col py-4'>
								<input 
									type="url" 
									name="themoviedbURL" 
									value={formData.themoviedbURL} 
									placeholder='The Movie DB URL (optional)'
									onChange={handleChange} 
									className="p-3 my-2 bg-gray-700 rounded" 
								/>
								<textarea 
									name="message" 
									value={formData.message}
									placeholder='Message'
									onChange={handleChange} 
									className="p-3 my-2 bg-gray-700 rounded" 
									required
								>
								</textarea>
								<input 
									type="email" 
									name="email" 
									value={formData.email} 
									placeholder='Your Email (optional)'
									onChange={handleChange} 
									className="p-3 my-2 bg-gray-700 rounded" 
								/>
								{/* {console.log(recaptchaConfig.siteKey)} */}
								<ReCAPTCHA
									sitekey={recaptchaConfig.siteKey}  // Replace with your Site Key
									onChange={value => setRecaptchaValue(value)}
									className='p-0 my-2 rounded'
                />
								<button
									type="submit"
									className='bg-red-600 py-3 mt-6 mb-4 rounded font-bold'
								>
									Submit
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
			</>
    );
}

export default ContactUs;
