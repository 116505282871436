import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export default function AlertBox({ type = 'info', title = 'Alert', message, color, clearAlert }) {
  if (message) {
    MySwal.fire({
      title: title,
      text: message,
      icon: type,
      confirmButtonText: 'Okay',
      confirmButtonColor: color || undefined, // Set the color if provided, else use default
      didClose: clearAlert
    });
  }

  return null;
}
