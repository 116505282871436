import React from 'react'
import SEO from '../components/SEO';

const Privacy = () => {
	return (
		<div className="flex justify-center items-center min-h-[calc(100vh-4rem)] pt-0 md:pt-0 lg:pt-6">
			<SEO
          title='Privacy Policy | Autism In The Media: Ensuring Fair Representation'
          description='Read the Privacy Policy of Autism In The Media, your trusted source for accurate representation of autism in film & TV. Know how we safeguard your data & uphold transparency.'
          keywords='Autism representation in media, Film and television, Privacy policy, Data safeguarding, Autism in Hollywood, Diverse autistic portrayal, User data protection'
          type='website' />
			
		  <div id="about-section" className="p-8 bg-black text-white max-w-2xl">

				<h1 className="text-4xl mb-6 text-green-400">Privacy Policy</h1>
				<p className="mb-4">This privacy policy ("policy") will help you understand how Autism in the Media ("AITM", "us", "we", "our") uses and protects the data you provide to us when you visit and use <a href="https://autisminthemedia.com">autisminthemedia.com</a> ("website", "service").
					We reserve the right to change this policy at any given time, of which you will be promptly updated. If you want to make sure that you are up to date with the latest changes, we advise you to frequently visit this page.</p>

					<h2 className="text-2xl md:3xl lg:3xl my-4 text-green-400">What User Data We Collect</h2>

			  <p className="mb-4">When you visit the website, we may collect the following data:

					* Your IP address.
					* Your contact information and email address.
					* Data profile regarding your online behavior on our website.
			</p>

			<h2 className="text-2xl md:3xl lg:3xl my-4 text-green-400">Data We Collect & Its Purpose</h2>

			<div className="mb-4">We are collecting your data for several reasons:
				<ul className="list-disc ml-8">
					<li>To better understand your needs.</li>
					<li>To improve our services and products.</li>
					<li>To customize our website according to your online behavior and personal preferences.</li>
				</ul>
			</div>

			<h2 className="text-2xl md:3xl lg:3xl my-4 text-green-400">Commitment to Data Security</h2>

			<p className="mb-4">AITM is committed to securing your data and keeping it confidential. AITM has done all in its power to prevent data theft, unauthorized access, and disclosure by implementing the latest technologies and software, which help us safeguard all the information we collect online.</p>

			<h2 className="text-2xl md:3xl lg:3xl my-4 text-green-400">Cookie Usage & Your Preferences</h2>

			<p className="mb-4">Once you agree to allow our website to use cookies, you also agree to use the data it collects regarding your online behavior (analyze web traffic, web pages you spend the most time on, and websites you visit).

			The data we collect by using cookies is used to customize our website to your needs. After we use the data for statistical analysis, the data is completely removed from our systems.

			Please note that cookies don't allow us to gain control of your computer in any way. They are strictly used to monitor which pages you find useful and which you do not so that we can provide a better experience for you.

			If you want to disable cookies, you can do it by accessing the settings of your internet browser. (Provide links for cookie settings for major internet browsers).
			</p>

			<h2 className="text-2xl md:3xl lg:3xl my-4 text-green-400">External Links & Your Privacy</h2>

			<p className="mb-4">Our website contains links that lead to other websites. If you click on these links AITM is not held responsible for your data and privacy protection. Visiting those websites is not governed by this privacy policy agreement. Make sure to read the privacy policy documentation of the website you go to from our website.</p>

			<h2 className="text-2xl md:3xl lg:3xl my-4 text-green-400">Data Collection Restrictions & Permissions</h2>

			<p className="mb-4">AITM will not lease, sell or distribute your personal information to any third parties, unless we have your permission. We might do so if the law forces us.</p>
		  </div>	
		</div>
	)
}

export default Privacy
