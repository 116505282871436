import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaList } from 'react-icons/fa';
import Main from '../components/Main';
import Row from '../components/Row';
import { movieRequests, tvShowRequests } from '../Requests';
import SEO from '../components/SEO';
import MediaTypeToggle from '../components/MediaTypeToggle';

const Home = () => {
  //const storedMediaType = localStorage.getItem('mediaType');
  //const [mediaType, setMediaType] = useState(storedMediaType || 'movie'); // 'movie' or 'tv'
  const [mediaType, setMediaType] = useState(localStorage.getItem('mediaType') || 'movie');
  
  const requestsByType = mediaType === 'movie' ? movieRequests : tvShowRequests;
  const titlesByDecade = [
    '1930s - 1980s',
    '1990s',
    '2000s',
    '2010s',
    '2020s'
  ];

  const handleToggleChange = () => {
    const newMediaType = mediaType === 'movie' ? 'tv' : 'movie';
    setMediaType(newMediaType);
    localStorage.setItem('mediaType', newMediaType);
  };

  // useEffect(() => {
  //   console.log('HOME: mediaType changed to:', mediaType);
  // }, [mediaType]);

  useEffect(() => {
    localStorage.setItem('mediaType', mediaType);
  }, [mediaType]);

  // console.log("HOME: mediaType in localStorage:", localStorage.getItem('mediaType'));

  return (
    <>
      <SEO
          title='Autism In The Media | Discover Diverse Autistic Characters in Film & TV'
          description='Dive into movies and TV shows that portray the true diversity of autism. Discover characters beyond the typical white male savant trope and celebrate authentic representation across genders and backgrounds.'
          keywords='autism, media representation, diversity, movies, TV shows, autistic spectrum'
          type='website' />
      <Main mediaType={mediaType} />
      {/* Toggle Component */}
      <div className="flex-auto">
        <MediaTypeToggle mediaType={mediaType} onToggleChange={handleToggleChange} />
        <div className="flex justify-left mt-11 pl-2 pb-5 md:pb-0 md:pl-6 lg:pb-0 lg:pl-8 xl:pl-11">
          <div className="pt-1 pr-2">
            <Link
              to={`/viewall/${mediaType}`}
              className="text-white font-medium"
            >
              <FaList className='text-yellow-500' />
            </Link>
          </div>
          <div>
            <Link
              to={`/viewall/${mediaType}`}
              className="text-white font-medium"
            >
              View As List
            </Link>
          </div>
        </div>
      </div>

      {titlesByDecade.map((title, index) => {
        const key = Object.keys(requestsByType)[index + 1]; // +1 to skip the 'all'
        return <Row key={title} rowID={index + 1} title={title} fetchURL={requestsByType[key]()} mediaType={mediaType} />;
      })}

    </>
  );
};

export default Home;
