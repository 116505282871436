import React from 'react';
import { Alert, Button } from "@material-tailwind/react";

function Icon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="h-6 w-6"
      >
        <path
          fillRule="evenodd"
          d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
          clipRule="evenodd"
        />
      </svg>
    );
}

function ErrorHandler({ errorType, errorMessage, onClose }) {
    const [open, setOpen] = React.useState(true);

    if (!errorMessage) {
        switch (errorType) {
            case "LOGIN_REQUIRED":
                errorMessage = "Please log in to save a movie.";
                break;
            case "ZAPME":
                errorMessage = "Lorem lorem Failed to connect to the Firestore database.  Lorem lorem Failed to connect to the Firestore database. Please try again later. Please log in to save a movie.";
                break;
            case "MOVIE_DB_FAILURE":
                errorMessage = "Failed to connect to The Movie DB API. Please try again later.";
                break;
            case "FIRESTORE_FAILURE":
                errorMessage = "Failed to connect to the Firestore database. Please try again later.";
                break;
            case "NETWORK_FAILURE":
                errorMessage = "Network error. Please check your internet connection and try again.";
                break;
            default:
				return null;  // Return nothing if error is unknown
        }
    }

return (
    open && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50" >
        <div className="mx-auto mt-10 max-w-screen-md min-w-min px-4 rounded shadow">
        <div className="flex w-full flex-col gap-2">

            <Alert
                variant="gradient"
                color="blue"
                open={open}
                icon={<Icon />}
                action={
                    <Button
                        variant="text"
                        color="white"
                        size="sm"
                        className="!absolute top-3 -right-1"
                        onClick={() => setOpen(false)}
                    >
                        Close
                    </Button>
                }
            >
                <div style={{ wordWrap: 'break-word' }}>{errorMessage}</div>
            </Alert>
            </div>
        </div>
    </div>
    )
  );
}

export default ErrorHandler;
