import api from '../services/api';
import React, { useEffect, useState } from 'react';
import Movie from './Movie';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

const Row = ({ title, fetchURL, rowID, mediaType }) => {
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(true);

  const saveToLocalStorage = (key, data) => {
    const dataWithTimestamp = {
      timestamp: new Date().getTime(),
      data,
    };
    localStorage.setItem(key, JSON.stringify(dataWithTimestamp));
  };

  const getFromLocalStorage = (key) => {
    const dataWithTimestamp = JSON.parse(localStorage.getItem(key));
    if (dataWithTimestamp) {
      const now = new Date().getTime();
      const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
      if (now - dataWithTimestamp.timestamp < oneDayInMilliseconds && dataWithTimestamp.data.length > 0) {
        return dataWithTimestamp.data;
      }
    }
    return null;
  };
  
  
  useEffect(() => {
    const fetchMovies = async () => {
      const uniqueKey = `${rowID}-${mediaType}`;

      let cachedMovies = getFromLocalStorage(uniqueKey);

      if (cachedMovies && cachedMovies.length > 0) {
        setMovies(cachedMovies);
        setLoading(false);
        return;  // Return early if data was found in cache
    }
  
      let currentPage = 1;
      let allMovies = [];
      let itemCount = 0;
      let morePagesAvailable = true;
      
      while (morePagesAvailable) {
        try {
            const response = await api.get(`${fetchURL}&page=${currentPage}`);

          const { items, item_count } = response.data;

          allMovies = [...allMovies, ...items];
          itemCount = item_count;

          morePagesAvailable = allMovies.length < itemCount;
          currentPage += 1;
        } catch (error) {
          if (error.response && error.response.status === 401) {
            console.error("Key issue.");
          } else {
            console.error("An error occurred:", error);
          }
          break;  // Exit the loop in case of an error
        }
      }

      setMovies(allMovies);
      setLoading(false);
      saveToLocalStorage(uniqueKey, allMovies);
    };

    fetchMovies();
  }, [fetchURL, rowID, mediaType]);

  const slideLeft = () => {
    var slider = document.getElementById('slider' + rowID);
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideRight = () => {
    var slider = document.getElementById('slider' + rowID);
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  return (
    <>
      <h2 className='text-white font-bold md:text-xl p-2 md:p-6 lg:p-8 xl:p-10'>{title}</h2>
      <div className='relative flex items-center group'>
        <MdChevronLeft
          onClick={ slideLeft}
          className='bg-white left-0 rounded-full absolute opacity-50 hover:opacity-100 cursor-pointer z-10 hidden group-hover:block'
          size={40}
        />
        <div
          id={'slider' + rowID}
          className='w-full h-full overflow-x-scroll whitespace-nowrap scroll-smooth scrollbar-hide relative'
        >
          {loading ? (
            <div>Loading...</div>
          ) : (
            movies.map((item, id) => (
              <Movie key={id} item={item} mediaType={mediaType} />
            ))
          )}
        </div>
        <MdChevronRight
          onClick={slideRight}
          className='bg-white right-0 rounded-full absolute opacity-50 hover:opacity-100 cursor-pointer z-10 hidden group-hover:block'
          size={40}
        />
      </div>
    </>
  );
};

export default Row;
