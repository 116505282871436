import React, { useState  } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SEO from '../components/SEO';
import { useError } from '../contexts/ErrorContext';
import { auth, sendPasswordReset  } from '../firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import AlertBox from '../components/AlertBox';

const ForgotPassword = () => {

  const [email, setEmail] = useState("");
	const { error, setError, clearError } = useError();
	const [alert, setAlert] = useState(null);

	const handleSubmit = () => {

		if (!navigator.onLine) {
			setError("You are offline. Please check your network connection.");
			return;
		}

		sendPasswordReset(email)
		.then(() => {
			// console.log("User sent password reset email.");
			setAlert("Password reset email sent. Please check your inbox.");
		})
		.catch((error) => {

			const errorCode = error.message.split('(')[1].split(')')[0];

			switch (errorCode) {
				case "auth/invalid-email":
					setError("The email address is not valid.");
					break;
				case "auth/missing-email":
					setError("Please enter an email address.");
					break;
				case "auth/email-not-found":
					setError("The email is not associated with any account.");
					break;
				case "auth/operation-not-allowed":
					setError("Email/password accounts are not enabled.");
					break;
				case "auth/network-request-failed":
					setError("The network connection was momentarily lost. Please try again.");	
					break;
				default:
					setError("An unknown error occurred. Please try again: ", error);	
			}
		});
	};

	const clearInfo = () => {
		setAlert(null);
	};

	const clearErrorBox = () => {
		setError(null);
		setAlert(null);
	};
	
	return (
		<>
    <div className='w-full min-h-screen relative'>
				<SEO
          title='Reset Your Password | Autism In The Media'
          description='Easily reset your password to continue contributing to a more accurate and diverse representation of autism in media on our platform.'
          keywords='Reset Password, Autism In The Media Password Reset, Password Recovery, Account Access, Retrieve Password, Password Reset Form'
          type='website' />
				<img
					className='hidden sm:block absolute inset-0 w-full h-full object-cover z-0'
					src='aitm-background.jpg'
					alt='/'
				/>
				<div className='bg-black/60 absolute inset-0 z-10'></div>
				<div className='flex items-start justify-center mt-0 min-h-screen px-4 py-0 md:py-0 lg:py-0 xlg:py-8 z-50 relative'>
						<div className='max-w-[450px] h-[240px] md:h-[260px] lg:h-[280px] bg-black/75 text-white'>
								<div className='max-w-[320px] mx-auto p-4 md:p-8 lg:p-10'>
										<h1 className='text-3xl font-bold'>Forgot Password</h1>
            <AlertBox type="error" title="Error" message={error} clearAlert={clearErrorBox} />
            <form onSubmit={handleSubmit} className='w-full flex flex-col py-4'>
              <input
                onChange={(e) => setEmail(e.target.value)}
                className='p-3 my-2 bg-gray-700 rounded'
                value={email}
                type='email'
                placeholder='Email'
                autoComplete='email'
                required
              />
              <button
                type="submit"
                className='bg-red-600 py-3 mt-6 mb-4 rounded font-bold'
              >
                Send password reset email
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
		</>
	)
}

export default ForgotPassword