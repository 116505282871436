import React from 'react'; 
import { Switch, Typography } from "@material-tailwind/react";

function MediaTypeToggle({ mediaType, onToggleChange }) {
  return (
    <div className="flex justify-left mt-11 pl-2 md:pl-6 lg:pl-8 xl:pl-11">
        <Switch
            label={
                <div>
                    <Typography color="white" className="font-medium">
                        {mediaType === 'movie' ? 'Movies' : 'TV Shows'}
                    </Typography>
                    <Typography variant="small" color="gray" className="font-normal">
                        Toggle to view Movies or TV Shows!
                    </Typography>
                </div>
            }
            checked={mediaType === 'movie'}
						color="green"
            onChange={onToggleChange}
            containerProps={{
                className: "-mt-5",
            }}
        />
    </div>
  );
}

export default MediaTypeToggle;
