import React, { useState, useEffect } from 'react';
import api from '../services/api';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { movieRequests, tvShowRequests } from '../Requests';
import SEO from '../components/SEO';
import ErrorHandler from '../components/ErrorHandler';

const ViewAll = () => {
	const { mediatype } = useParams() || 'movie';
	const fetchURL = mediatype === 'movie' ? movieRequests.all() : tvShowRequests.all();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [everything, setEverything] = useState(null);
	const [media, setMedia] = useState([]);
  const navigate = useNavigate();

	const getFromLocalStorage = (key) => {
    const allMedia = JSON.parse(localStorage.getItem(key));
		// console.log('allMedia:', allMedia);

    if (allMedia) {
      return allMedia;
    }
    return null;
  };

	useEffect(() => {
    const fetchMedia = async () => {
			const cacheKey = `allItems-${mediatype}`;

			// console.log('cacheKey:', cacheKey);

      let cachedData = getFromLocalStorage(cacheKey);
			// console.log('cachedData:', cachedData);

      if (cachedData && cachedData.length > 0) {
				// console.log('Setting Media to cachedData:', cachedData);
        setMedia(cachedData);
        setLoading(false);
        return;  // Return early if data was found in cache
			}
  
      let currentPage = 1;
      let allMedia = [];
      let itemCount = 0;
      let morePagesAvailable = true;
      
      while (morePagesAvailable) {
        try {
					// console.log('fetchURL:', fetchURL);
					const response = await api.get(`${fetchURL}&page=${currentPage}`);

          const { items, item_count } = response.data;

          allMedia = [...allMedia, ...items];
          itemCount = item_count;

          morePagesAvailable = allMedia.length < itemCount;
          currentPage += 1;
        } catch (error) {
          if (error.response && error.response.status === 401) {
            console.error("Key issue.");
          } else {
            console.error("An error occurred:", error);
          }
          break;  // Exit the loop in case of an error
        }
      }

      setMedia(allMedia);
      setLoading(false);
    };

    fetchMedia();
  }, [fetchURL, mediatype]);

	// Close error handler modal
	const handleCloseError = () => {
		setError(null);
	}

	const handleBackClick = () => {
		navigate(-1);  // This will navigate back
	};

  function formatDate(dateStr) {
    const parts = dateStr.split("-");
    return `${parts[1]}/${parts[2]}/${parts[0]}`;
  }

	return (
		<>
			<SEO
          title='View All Media | Discover Diverse Autistic Characters in Film & TV'
          description='View all of AITM movies and TV shows that portray the true diversity of autism. Discover characters beyond the typical white male savant trope and celebrate authentic representation across genders and backgrounds.'
          keywords='autism, media representation, diversity, movies, TV shows, autistic spectrum'
          type='website' />
		  {error && <ErrorHandler errorType={error} onClose={handleCloseError} />}
			<div className="pt-4 md:pt-0 lg:pt-0 pb-0 md:pb-0 lg:pb-0">
          <button className="rounded cursor-pointer border text-white border-gray-300 py-2 px-5 ml-0 md:ml-10 lg:ml-10" onClick={handleBackClick}>Back</button>
      </div>	
				<div className='flex justify-center text-white font-bold md:text-xl p-2 md:p-6 lg:text-3xl lg:p-8 xl:p-10'>View All {mediatype === 'movie' ? 'Movies' : 'TV Shows'}</div>	
			<div className='flex justify-center items-center min-h-[calc(100vh-4rem)] pt-0 md:pt-0 lg:pt-6 text-white'>
				<ul className="divide-y divide-gray-800 max-w-2xl">
					{media.map((item, id) => (
						<li key={id} className="flex gap-x-6 py-5">
							<div className="flex">
								<div className="p-0 m-0">
									<Link
										to={`/overview/${mediatype}/${item.id}`}
										className='flex-none w-48 block p-0 m-0'
									>
										<img 
											className="h-42 p-0" 
											src={`https://www.themoviedb.org/t/p/w300/${item.poster_path}`} 
											alt="" 
										/>
									</Link>
								</div>
								<div className="pl-4 m-0">
									<Link
										to={`/overview/${mediatype}/${item.id}`}
										className="text-lg font-semibold block leading-6 text-white"
									>
										{item?.name || item?.title} &nbsp; 
										{item ? (
											<>
												({mediatype === 'movie' ? (item?.release_date ? formatDate(item.release_date) : 'N/A') : (item?.first_air_date ? formatDate(item.first_air_date) : 'N/A')})
											</>
											) : null
										}
									</Link>
									<p className="mt-1 leading-5 text-gray-400">{item.overview}</p>
								</div>
							</div>
						</li>
					))}
				</ul>
			</div>
		</>
	)
}

export default ViewAll