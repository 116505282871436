import React, { useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async'; 
import { AuthProvider } from './contexts/AuthContext';
import Content from './components/Content';
import CookieConsent from "react-cookie-consent";
import { ErrorProvider } from './contexts/ErrorContext';
import { googleAnalyticsConfig } from './firebase';

function sendPageView() {
  if (window.gtag) {
    window.gtag('event', 'page_view', {
      page_location: window.location.href,
      page_path: window.location.pathname,
      page_title: document.title,
    });
  }
}

function PageViewTracker() {
  const location = useLocation();

  useEffect(() => {
    sendPageView();
  }, [location]);

  return null;
}

function App() {

  const isGA4ScriptLoaded = () => {
    return !!document.querySelector(`script[src="https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsConfig.measurementId}"]`);
  };

  const loadGA4Script = () => {
    const script1 = document.createElement('script');
    script1.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsConfig.measurementId}`;
    script1.async = true;

    script1.onload = () => {
      const script2 = document.createElement('script');
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${googleAnalyticsConfig.measurementId}');
      `;
      document.head.appendChild(script2);
    };

    document.head.appendChild(script1);
  };

  useEffect(() => {
    const userConsent = localStorage.getItem("userConsentCookie");
    if (userConsent && !isGA4ScriptLoaded()) {
      loadGA4Script();
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("userConsentCookie", "true");
    if (!isGA4ScriptLoaded()) {
      loadGA4Script();
    }
  };

  return (
    <>
      <HelmetProvider>
        <Router>
        <PageViewTracker />
          <ErrorProvider>
            <AuthProvider>
              <Content />
            </AuthProvider>
          </ErrorProvider>
        </Router>
      </HelmetProvider>
      <CookieConsent
        location="bottom"
        buttonText="I Understand"
        cookieName="userConsentCookie"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
        onAccept={handleAccept}
      >
        This website uses cookies to enhance the user experience.{" "}
        <span style={{ fontSize: "10px" }}>
          <a href="/privacy">Learn more</a>
        </span>
      </CookieConsent>
    </>
  );
}

export default App;
