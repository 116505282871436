import axios from 'axios';

const key = process.env.REACT_APP_TMDB_API_KEY;

const instance = axios.create({
  baseURL: "https://api.themoviedb.org/3/", // e.g., 'https://api.themoviedb.org/3/'
  headers: {
    Authorization: `Bearer ${key}`,
    Accept: 'application/json'
  }
});

export default instance;
