import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function SEO({title, description, keywords, type}) {
return (
<Helmet>
{ /* Standard metadata tags */ }
<title>{title}</title>
<meta name='description' content={description} />
<meta name='keywords' content={keywords} />
<meta name="robots" content="index, follow" />
<meta name="author" content="Autism In The Media" />
{ /* End standard metadata tags */ }
{ /* Facebook tags */ }
<meta property="og:type" content={type} />
<meta property="og:title" content={title} />
<meta property="og:description" content={description} />
<meta property="og:image" content="https://autisminthemedia.com/aitm-og.jpg" />
<meta property="og:url" content="https://autisminthemedia.com" />
{ /* End Facebook tags */ }
{ /* Twitter tags */ }
<meta name="twitter:creator" content="Autism In The Media" />
<meta name="twitter:card" content={type} />
<meta name="twitter:title" content={title} />
<meta name="twitter:description" content={description} />
{ /* End Twitter tags */ }
</Helmet>
)
}