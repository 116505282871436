import React, { useEffect, useState } from 'react';
import api from '../services/api';
import { Tooltip, Typography } from "@material-tailwind/react";
import { movieRequests, tvShowRequests, requests } from '../Requests';
import { FaCircle } from 'react-icons/fa';
import MoviePlayer from './MoviePlayer';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import AlertBox from '../components/AlertBox';

const Main = ({ mediaType }) => {
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [firestoreMedia, setFirestoreMedia] = useState(null);
  const [youtubeVideoId, setYoutubeId] = useState(null);
  const [allItems, setAllItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [randomMedia, setRandomMedia] = useState(null); 
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    const fetchItems = async () => {
      const cacheKey = `allItems-${mediaType}`;
      const timestampKey = `timestamp-${mediaType}`;

      const cachedData = localStorage.getItem(cacheKey);
      const cachedTimestamp = localStorage.getItem(timestampKey);
      const currentTime = new Date().getTime();

      // Check if cached data exists and is less than one day old
      if (cachedData && cachedTimestamp && (currentTime - cachedTimestamp < 86400000)) {
        setAllItems(JSON.parse(cachedData));
        setLoading(false);
      } else {
        let currentPage = 1;
        let fetchedItems = [];
        let itemCount = 0;
        let morePagesAvailable = true;

        const fetchURL = mediaType === 'movie' ? movieRequests.all() : tvShowRequests.all();

        while (morePagesAvailable) {
          try {
            const response = await api.get(`${fetchURL}&page=${currentPage}`);
            const { items, item_count } = response.data;

            fetchedItems = [...fetchedItems, ...items];
            itemCount = item_count;

            morePagesAvailable = fetchedItems.length < itemCount;
            currentPage += 1;
          } catch (error) {
            console.error("An error occurred:", error);
            break;  // Exit the loop in case of an error
          }
        }

        setAllItems(fetchedItems);
        setLoading(false);
        localStorage.setItem(cacheKey, JSON.stringify(fetchedItems));
        localStorage.setItem(timestampKey, currentTime.toString());
      }
    };

    fetchItems();
  }, [mediaType]);  // dependency on mediaType

  useEffect(() => {
      if (!loading && allItems.length > 0) {
        const randomMedia = allItems[Math.floor(Math.random() * allItems.length)];
        setRandomMedia(randomMedia);
          // Determine the URL to fetch additional details based on the media type
          const detailURL = mediaType === 'movie'
              ? requests.requestMovie(randomMedia.id)
              : requests.requestTv(randomMedia.id);

          // Fetch additional details for the randomly selected media item
          api.get(detailURL)
              .then((detailResponse) => {
                  const fetchedDetail = detailResponse.data;
                  setSelectedMedia(fetchedDetail);
              })
              .catch(error => {
                  console.error("Error fetching movie/TV show detail:", error);
              });

          // Determine the URL to fetch the YouTube video ID based on the media type
          const videoURL = mediaType === 'movie'
              ? requests.requestVideo(randomMedia.id)
              : requests.requestTvVideo(randomMedia.id);

          // Fetch the YouTube video ID for the randomly selected media item
          api.get(videoURL)
              .then((videoResponse) => {
                  const officialUSVideos = videoResponse.data.results.filter(video =>
                      video.iso_3166_1 === "US"
                  );

                  if (officialUSVideos.length > 0) {
                      setYoutubeId(officialUSVideos[0].key);
                  } else {
                      console.log("No official US video found for the movie/TV show");
                  }
              })
              .catch(error => {
                  console.error("Error fetching video ID:", error);
              });
      }
  }, [allItems, loading, mediaType]);

  useEffect(() => {
    if (selectedMedia) {

      // Query Firestore for a document matching the media's id
      const mediaRef = doc(db, 'media', randomMedia?.id.toString()); 

      getDoc(mediaRef)
        .then((doc) => {
          if (doc.exists) {
            setFirestoreMedia(doc.data());
          } else {
            // console.log("No matching media in Firestore!");
          }
      }).catch(error => {
          // console.log("Error fetching Firestore document:", error);
      }); 

    }
  }, [selectedMedia, randomMedia?.id]);

  const truncateString = (str, num) => {
    if (str?.length > num) {
      return str.slice(0, num) + '...';
    } else {
      return str;
    }
  };

  function formatDate(dateStr) {
    const parts = dateStr.split("-");
    return `${parts[1]}/${parts[2]}/${parts[0]}`;
  }

  const clearInfo = () => {
		setAlert(null);
	};

  // console.log("MAIN: mediaType in localStorage:", localStorage.getItem('mediaType'));

  return (
    <div className='w-full h-[650px] md:h-[650px] lg:h-[650px] pl-0 md:pl-2 lg:pl-1 text-white'>
      <div className='w-full h-full'>
        <div className='absolute w-full h-[650px] md:h-[650px] lg:h-[650px] bg-gradient-to-r from-black'></div>
        <AlertBox type="info" title="Information" message={alert} color="#3498db" clearAlert={clearInfo} />
        {selectedMedia && selectedMedia.backdrop_path &&
          <img
            className='w-full h-full object-cover'
            src={`https://image.tmdb.org/t/p/original/${selectedMedia.backdrop_path}`}
            alt={mediaType === 'movie' ? selectedMedia.title : selectedMedia.name}
          />
        }

        <div className='absolute w-full top-[14%] p-0 sm:pt-8 md:p-8'>
          <h1 className='text-3xl md:text-5xl font-bold'>{mediaType === 'movie' ? selectedMedia?.title : selectedMedia?.name}</h1>
          <div className='flex items-center space-x-2 text-gray-400 text-sm md:text-base pt-6'>

            <span> Released: {mediaType === 'movie' ? (selectedMedia?.release_date ? formatDate(selectedMedia.release_date) : 'N/A') : (selectedMedia?.first_air_date ? formatDate(selectedMedia.first_air_date) : 'N/A')}</span>
            <FaCircle size={6} />
            
            {selectedMedia && selectedMedia.genres && selectedMedia.genres.length > 0 &&
              <span> Genres: {selectedMedia?.genres?.map(genre => genre.name).join(', ')}</span>
            }
            {mediaType === 'movie' && selectedMedia && selectedMedia.runtime && (
              <>
                  <FaCircle size={6} />
                  <span> Runtime: {selectedMedia?.runtime} minutes</span>
              </>
            )}
          </div>


          {selectedMedia && selectedMedia.credits.crew && selectedMedia.credits.crew.filter(member => member.job === "Director").length > 0 &&
              <>
                <div className='text-sm md:text-base text-gray-400'>
                    <span>Directed by: {
                      selectedMedia.credits.crew
                        .filter(member => member.job === "Director")
                        .map(director => director.name)
                        .join(', ')
                    }</span>
                </div>
              </>
            }




          {selectedMedia && selectedMedia.created_by && selectedMedia.created_by.length > 0 &&
            <>
            <div className='text-sm md:text-base text-gray-400'>
                <span>Created by: {selectedMedia?.created_by?.map(creator => creator.name).join(', ')}</span>
            </div>
            </>
          }
          {selectedMedia && selectedMedia.number_of_seasons && 
            <>
                <div className='text-sm md:text-base text-gray-400'>
                  <span>Seasons: {selectedMedia?.number_of_seasons}</span>
                </div>
            </>
          }
          {selectedMedia && selectedMedia.homepage && 
            <>
                <div className='text-sm md:text-base text-gray-400 flex flex-nowrap'>
                    <span>Visit Homepage: </span>
                    <a href={selectedMedia?.homepage} rel="noreferrer" target='_blank' title="Visit Homepage">
                        <img src="/social-link.svg" alt="Visit Homepage" className="h-6 w-auto ml-2" />
                    </a>
                </div>
            </>
          }

          <div className='mt-6 mb-6'>
            {<MoviePlayer passedMediaType={mediaType} videoId={youtubeVideoId} mediaItem={selectedMedia} />}
          </div>
          <div className='my-4 text-2xl'>Overview</div>
          <div className='w-full max-w-[90%] md:max-w-[70%] lg:max-w-[50%] xl:max-w-[35%] text-gray-200'>{truncateString(selectedMedia?.overview, 300)}</div>
          <div className="my-4 mx-0 px-0 flex flex-col w-full md:w-3/5 lg:w-3/5">
            <div className="overflow-x-auto">
                <div className="w-full inline-block align-middle">
                    <div className="w-full md:w-[95%] lg:w-[80%] xl:w-[60%] mr-0 md:mr-20 lg:mr-10 overflow-x-auto">
                        <table className="min-w-full">
                            <tbody>
                                <tr>
                                    <td className="pr-0 md:pr-6 lg:pr-6 pb-2 md:pb-4 lg:pb-6 text-sm font-medium">
                                        <p className="text-yellow-500"><strong className="text-yellow">Character Name</strong></p>
                                        <p className="flex items-center">
                                          <span>{firestoreMedia?.characterName}</span>
                                          {
                                            (firestoreMedia?.characterDescription !== undefined && firestoreMedia?.characterDescription !== null && firestoreMedia?.characterDescription !== '') && (
                                              <Tooltip
                                                content={
                                                  <div className="w-80">
                                                    <Typography className="text-purple-400 px-6 pt-4 pb-1 font-medium text-lg">
                                                      Character Description
                                                    </Typography>
                                                    <Typography
                                                      variant="small"
                                                      color="white"
                                                      className="px-6 pt-1 pb-4 font-normal opacity-80"
                                                    >
                                                      {firestoreMedia?.characterDescription}
                                                    </Typography>
                                                  </div>
                                                }
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  fill="none"
                                                  viewBox="0 0 24 24"
                                                  stroke="currentColor"
                                                  strokeWidth={2}
                                                  className="h-5 w-5 cursor-pointer text-purple-400 ml-2" 
                                                >
                                                  <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                                  />
                                                </svg>
                                              </Tooltip>
                                            )
                                          } 
                                        </p>
                                    </td>
                                    <td className="pr-0 md:pr-4 lg:pr-6 pb-2 md:pb-4 lg:pb-6 text-sm whitespace-nowrap">
                                        <p className="text-yellow-500"><strong>Character Type</strong></p>
                                        <p className="flex items-center"> {/* Use flexbox to align items center */}
                                          <span>{firestoreMedia?.characterType}</span>
                                          {firestoreMedia?.characterType.includes("Autistic-Coded") && (
                                            <Tooltip
                                              content={
                                                <div className="w-80">
                                                  <Typography className="text-purple-400 px-6 pt-4 pb-1 font-medium text-lg">
                                                    Autistic-Coded
                                                  </Typography>
                                                  <Typography
                                                    variant="small"
                                                    color="white"
                                                    className="px-6 pt-1 pb-4 font-normal opacity-80"
                                                  >
                                                    Writing or performing a character with several traits common to autistic people 
                                                    but without confirming it or explicitly discussing it.
                                                  </Typography>
                                                </div>
                                              }
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                                className="h-5 w-5 cursor-pointer text-purple-400 ml-2" 
                                              >
                                                <path
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                                />
                                              </svg>
                                            </Tooltip>
                                          )}
                                        </p>
                                    </td>
                                    <td className="pr-0 md:pr-4 lg:pr-6 pb-2 md:pb-4 lg:pb-6 text-sm whitespace-nowrap">
                                        <p className="text-yellow-500"><strong>Character Age</strong></p>
                                        <p>{firestoreMedia?.characterAge}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="pr-0 md:pr-4 lg:pr-6 text-sm whitespace-nowrap">
                                        <p className="text-yellow-500"><strong>Character Gender</strong></p>
                                        <p>{firestoreMedia?.characterGender}</p>
                                    </td>
                                    <td className="pr-0 md:pr-4 lg:pr-6 text-sm whitespace-nowrap">
                                        <p className="text-yellow-500"><strong>Character Ethnicity</strong></p>
                                        <p>{firestoreMedia?.characterEthnicity}</p>
                                    </td>
                                    <td className="pr-0 md:pr-4 lg:pr-6 text-sm whitespace-nowrap">
                                        <p className="text-yellow-500"><strong>Autistic Actor?</strong></p>
                                        <p>{firestoreMedia?.actorAutistic ? 'Yes' : 'No'}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
