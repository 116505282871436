const requests = {
  requestAll: () => `list/8272119?language=en`,
  request6080: () => `list/8272070?language=en`,
  request90: () => `list/8272090?language=en`,
  request2000: () => `list/8272093?language=en`,
  request2010: () => `list/8272095?language=en`,
  request2020: () => `list/8272117?language=en`,
  requestTvAll: () => `list/8272804?language=en`,
  requestTv6080: () => `list/8272805?language=en`,
  requestTv90: () => `list/8272806?language=en`,
  requestTv2000: () => `list/8272807?language=en`,
  requestTv2010: () => `list/8272808?language=en`,
  requestTv2020: () => `list/8272809?language=en`, 
  requestOne: () => `list/8272145?language=en`,
  requestMovie: (movieId) => `movie/${movieId}?append_to_response=credits&language=en`,
  requestVideo: (movieId) => `movie/${movieId}/videos?language=en`,
  requestTv: (tvId) => `tv/${tvId}?append_to_response=credits&language=en`,
  requestTvVideo: (tvId) => `tv/${tvId}/videos?language=en`,
  requestHeadShot: (photoId) => `https://www.themoviedb.org/t/p/w138_and_h175_face${photoId}`,
  requestProfileImg: (profilePath) => `https://image.tmdb.org/t/p/w185${profilePath}`,
  requestPerson: (personId) => `person/${personId}?language=en&page=1`,
  requestPersonMovieCredits: (personId) => `person/${personId}/movie_credits?language=en`,
  requestDetectMovie: (id) => `movie/${id}?`,
  requestDetectTv: (id) => `tv/${id}?`,

  };

  const movieRequests = {
    all: requests.requestAll,
    decade6080: requests.request6080,
    decade90: requests.request90,
    decade2000: requests.request2000,
    decade2010: requests.request2010,
    decade2020: requests.request2020
  };
  
  const tvShowRequests = {
    all: requests.requestTvAll,
    decade6080: requests.requestTv6080,
    decade90: requests.requestTv90,
    decade2000: requests.requestTv2000,
    decade2010: requests.requestTv2010,
    decade2020: requests.requestTv2020
  };
  
  export { movieRequests, tvShowRequests, requests };
