import React, { useEffect, useState  } from 'react';
import SEO from '../components/SEO';
import { Link, useNavigate } from 'react-router-dom';
import { useError } from '../contexts/ErrorContext';
import { auth, signInUser, signInWithGoogle } from '../firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import AlertBox from '../components/AlertBox';

const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
	const [user, loading] = useAuthState(auth);
	const navigate = useNavigate();
	const { error, setError, clearError } = useError();
	const [alert, setAlert] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();

		signInUser(email, password)
		.then(() => {
			// console.log("User logged in.");
			navigate("/");
		})
		.catch((error) => {
			// console.error("Error registering user:", error);
			const errorCode = error.message.split('(')[1].split(')')[0];

			switch (errorCode) {
				case "auth/invalid-email":
					setError("The email address is not valid.");
					break;
				case "auth/invalid-login-credentials":
					setError("Invalid login credentials.");
					break;
				case "auth/operation-not-allowed":
					setError("Email/password accounts are not enabled.");
					break;
				case "auth/network-request-failed":
					setError("The network connection is lost. Please try again later.");
					break;
				default:
					setError("An unknown error occurred:", error);	
			}
		});
	};

  const handleGoogleSignIn = () => {

		signInWithGoogle()
		.then(() => {
			// console.log("User logged in.");
			navigate("/");
		})
		.catch((error) => {
			// console.error("Error registering user:", error);
			const errorCode = error.message.split('(')[1].split(')')[0];

			switch (errorCode) {
				case "auth/invalid-email":
					setError("The email address is not valid.");
					break;
				case "auth/invalid-login-credentials":
					setError("Invalid login credentials.");
					break;
				case "auth/operation-not-allowed":
					setError("Email/password accounts are not enabled.");
					break;
				case "auth/network-request-failed":
					setError("The network connection is lost. Please try again later.");
					break;
				case "auth/popup-closed-by-user":
					break;
				default:
					setError("An unknown error occurred:", error);	
			}
		});
	};

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/");
  }, [user, loading, navigate]);

	const clearErrorBox = () => {
		setError(null);
		setAlert(null);
	};

  return (
    <div className='w-full min-h-screen relative'>
        <SEO
          title='Sign In to Autism In The Media | Dive Deeper into Diverse Representation'
          description='Already part of our community? Sign in to explore more movies and TV shows that champion authentic representation of autism, and join the advocacy.'
          keywords='sign in, login, autism, media representation, member access, movies, TV shows'
          type='website' />
        <img
            className='hidden sm:block absolute inset-0 w-full h-full object-cover z-0'
            src='aitm-background.jpg'
            alt='/'
        />
        <div className='bg-black/60 absolute inset-0 z-10'></div>
        <div className='flex items-start justify-center mt-0 min-h-screen px-4 py-0 md:py-0 lg:py-0 xlg:py-8 z-50 relative'>
            <div className='max-w-[450px] h-[450px] px-10 mx-auto bg-black/75 text-white'>
                <div className='max-w-[320px] mx-auto py-4 md:py-8 lg:py-4'>
                    <h1 className='text-3xl font-bold'>Sign In</h1>
            <AlertBox type="error" title="Error" message={error} clearAlert={clearErrorBox} />
            <form onSubmit={handleSubmit} className='w-full flex flex-col py-4'>
              <input
                onChange={(e) => setEmail(e.target.value)}
                className='p-3 my-2 bg-gray-700 rounded'
                value={email}
                type='email'
                placeholder='Email'
                autoComplete='email'
                required
              />
              <input
                onChange={(e) => setPassword(e.target.value)}
                className='p-3 my-2 bg-gray-700 rounded'
                type='password'
                value={password}
                placeholder='Password'
                autoComplete='current-password'
                required
              />
              <div className="mr-2">
                <Link className='text-green-300 underline' to="/forgot-password">Forgot Password</Link>
              </div>
              <button
                type="submit"
                className='bg-red-600 py-3 mt-6 mb-4 rounded font-bold'
              >
                Sign In
              </button>
              <button 
								type="button"
								className="bg-red-600 p-3 mb-2 rounded font-bold"
								onClick={handleGoogleSignIn}
							>
								Sign in with Google
							</button>
              <p className='py-8'>
                <span className='text-gray-600'>New to AITM? </span>{' '}
                <Link to='/signup'> Sign Up</Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
