import { useLocation, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import Navbar from './Navbar';
import Home from '../pages/Home';
import About from '../pages/About';
import Signin from '../pages/Signin';
import ForgotPassword from '../pages/ForgotPassword';
import Signup from '../pages/Signup';
import ContactUs from '../pages/ContactUs';
import Privacy from '../pages/Privacy';
import Terms from '../pages/Terms';
import Overview from '../pages/Overview';
import Account from '../pages/Account';
import Person from '../pages/Person';
import ViewAll from '../pages/ViewAll';
import NotFound from '../pages/NotFound';
import ProtectedRoute from './ProtectedRoute';
import Footer from './Footer';
import { googleAnalyticsConfig } from '../firebase';

const Content = () => {
  const location = useLocation();
	useEffect(() => {
    // Scroll to top
    window.scrollTo(0, 0);

    // Log GA4 pageview
    if (typeof window.gtag === 'function') {
        window.gtag('config', googleAnalyticsConfig.measurementId, {
            'page_path': location.pathname + location.search
        });
    }
	}, [location]);

	function isKnownRoute(path) {
    const exactRoutes = [
      '/',
      '/about',
      '/signin',
      '/forgot-password',
      '/signup',
      '/contact-us',
      '/privacy',
      '/account',
      '/terms'
    ];

    const parameterizedStarts = [
      '/person/',
      '/overview/',
			'/viewall/'
    ];

    return exactRoutes.includes(path) || parameterizedStarts.some(start => path.startsWith(start));
  }

	return (
		<>
			{ isKnownRoute(location.pathname) && <Navbar /> }
			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/about' element={<About />} />
				<Route path='/signin' element={<Signin />} />
				<Route path='/contact-us' element={<ContactUs />} />
				<Route path='/forgot-password' element={<ForgotPassword/>} />
				<Route path='/signup' element={<Signup />} />
				<Route path='/privacy' element={<Privacy />} />
				<Route path='/terms' element={<Terms />} />
				<Route path="/person/:personid" element={<Person />} />
				<Route path="/overview/:mediatype/:id" element={<Overview />} />
				<Route path="/viewall/:mediatype" element={<ViewAll />} />

				<Route
					path='/account'
					element={
						<ProtectedRoute>
							<Account />
						</ProtectedRoute>
					}
				/>

				{/* This is the catch-all route */}
				<Route path='*' element={<NotFound />} />
			</Routes>
			{ isKnownRoute(location.pathname) && !['/signin', '/signup'].includes(location.pathname) && <Footer /> }
		</>
	);
}

export default Content;