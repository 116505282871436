import React, { useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext';
import { doc, updateDoc, getDoc, onSnapshot, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import ErrorHandler from './ErrorHandler';

function MoviePlayer({ passedMediaType, videoId, mediaItem }) {
    const [showPlayer, setShowPlayer] = useState(false);
    const isMobile = window.innerWidth <= 768;
    const navigate = useNavigate();
    const [like, setLike] = useState(false);
    const { user } = useAuth();
    const [error, setError] = useState(null);
    const userDoc = user?.uid ? doc(db, 'users', user.uid) : null;
    const location = useLocation();

    const opts = isMobile ? {
        height: '240',
        width: '320',
        playerVars: {
            autoplay: 1
        }
    } : {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 1
        }
    };

    useEffect(() => {
        if (!userDoc) return;
        
        const unsubscribe = onSnapshot(userDoc, (docSnapshot) => {
            const userDocData = docSnapshot.data();
            if (userDocData && userDocData.savedShows) {
            const savedShows = userDocData.savedShows || [];
            const isSaved = savedShows.some(show => show?.id === mediaItem?.id);
            setLike(isSaved);
            }
        });
        
        // Remember to unsubscribe when the component unmounts
        return () => unsubscribe();
    }, [mediaItem, userDoc]);

    const handleCloseError = () => {
        setError(null);
    };

    const saveShow = async () => {
        // Check if the user is not logged in
        if (!user?.uid) {
          setError('LOGIN_REQUIRED');
          return;
        }
    
        try {
          const showData = {
            id: mediaItem?.id,
            title: mediaItem?.title || mediaItem?.name,
            img: mediaItem?.backdrop_path
          };
    
          if (like) {
            await updateDoc(userDoc, {
              savedShows: arrayRemove(showData)
            });
          } else {
            await updateDoc(userDoc, {
              savedShows: arrayUnion(showData)
            });
          }
    
          setLike(!like);
    
        } catch (err) {
          console.error(err);
          setError('DATABASE_ERROR');
        }
      };
    
    const handleClick = () => {
        navigate(`/overview/${passedMediaType}/${mediaItem?.id}`);
    };

    return (
        <>
            {error && <ErrorHandler errorType={error} onClose={handleCloseError} />}
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {videoId && (
                    <button onClick={() => setShowPlayer(true)} className='rounded cursor-pointer border bg-gray-300 text-black border-gray-20 py-2 px-5'>Play Trailer</button>
                )}
                {!location.pathname.startsWith('/overview') && (
                    <button
                        onClick={handleClick}
                        className={`rounded cursor-pointer border text-white border-gray-300 py-2 px-5 ${videoId ? 'ml-4' : ''}`}
                    >
                        View Details
                    </button>
                )}
                <div
                    className='inline-block ml-4 cursor-pointer' 
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        saveShow();
                    }}
                >
                    {like ? (
                        <FaHeart className='text-gray-300' size='2em' />
                    ) : (
                        <FaRegHeart className='text-gray-300' size='2em' />
                    )}
                </div>
                
                {showPlayer && (
                    <div style={styles.overlay}>
                        <div style={styles.modal}>
                            <button style={styles.closeButton} onClick={() => setShowPlayer(false)}>X</button>
                            <YouTube videoId={videoId} opts={opts} />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
    },
    modal: {
        position: 'relative',
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '10px'
    },
    closeButton: {
        position: 'absolute',
        right: '10px',
        top: '10px',
        cursor: 'pointer',
        background: 'red',
        color: 'white',
        border: 'none',
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        textAlign: 'center',
        fontSize: '16px',
        lineHeight: '30px'
    }
};

export default MoviePlayer;
