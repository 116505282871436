import React, { useState, useEffect } from 'react';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { doc, updateDoc, getDoc, onSnapshot, arrayUnion, arrayRemove } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import ErrorHandler from './ErrorHandler';

const Movie = ({ item, mediaType }) => {
  const [like, setLike] = useState(false);
  const { user } = useAuth();
  const [error, setError] = useState(null);
  const displayTitle = mediaType === 'movie' ? item.title : item.name;
  const userDoc = user?.uid ? doc(db, 'users', user.uid) : null;

  useEffect(() => {
    if (!userDoc) return;
  
    const unsubscribe = onSnapshot(userDoc, (docSnapshot) => {
      const userDocData = docSnapshot.data();
      if (userDocData && userDocData.savedShows) {
        const savedShows = userDocData.savedShows || [];
        const isSaved = savedShows.some(show => show.id === item.id);
        setLike(isSaved);
      }
    });
  
    // Remember to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, [item.id, userDoc]);
  
  // useEffect(() => {
  //   const checkIfSaved = async () => {
  //     if (!userDoc) return;

  //     const userData = await getDoc(userDoc);
  //     if (userData.exists) {
  //       const savedShows = userData.data().savedShows || [];
  //       const isSaved = savedShows.some(show => show.id === item.id);
  //       setLike(isSaved);
  //     }
  //   };

  //   checkIfSaved();

  // }, [item.id, userDoc]);

  const handleCloseError = () => {
    setError(null);
  };

  const saveShow = async () => {
    // Check if the user is not logged in
    if (!user?.uid) {
      setError('LOGIN_REQUIRED');
      return;
    }

    try {
      const showData = {
        id: item.id,
        title: item.title || item.name,
        img: item.backdrop_path
      };

      if (like) {
        await updateDoc(userDoc, {
          savedShows: arrayRemove(showData)
        });
      } else {
        await updateDoc(userDoc, {
          savedShows: arrayUnion(showData)
        });
      }

      setLike(!like);

    } catch (err) {
      console.error(err);
      setError('DATABASE_ERROR');
    }
  };

  return (
    <>
      {error && <ErrorHandler errorType={error} onClose={handleCloseError} />}
      <Link 
        to={`/overview/${mediaType}/${item?.id}`} 
        className="w-[160px] sm:w-[200px] md:w-[240px] lg:w-[280px] inline-block cursor-pointer relative p-2"
        onClick={e => {
          if (error) {
              e.preventDefault(); // Prevent navigation
              e.stopPropagation(); // Stop further propagation
          }
        }}
      >
        <img
          className='w-full h-auto block'
          src={`https://image.tmdb.org/t/p/w500/${item?.backdrop_path}`}
          alt={displayTitle} />
        <div className='absolute top-0 left-0 w-full h-full hover:bg-black/80 opacity-0 hover:opacity-100 text-white'>
          <p className='white-space-normal text-xs md:text-sm font-bold flex justify-center items-center h-full text-center'>
            {displayTitle}
          </p>
          <p
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              saveShow();
            }}
          >
            {like ? (
              <FaHeart className='absolute top-4 left-4 text-gray-300' />
            ) : (
              <FaRegHeart className='absolute top-4 left-4 text-gray-300' />
            )}
          </p>
        </div>
      </Link>
    </>
  );
};

export default Movie;