import React, { useEffect, useState } from "react";
import SEO from '../components/SEO';
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from 'react-router-dom';
import { useError } from '../contexts/ErrorContext';
import AlertBox from '../components/AlertBox';
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaConfig } from "../firebase";

import {
	auth,
	registerUser,
	signInWithGoogle,
} from '../firebase';

function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
	const [user, loading, authError] = useAuthState(auth);
	const navigate = useNavigate();
	const { error, setError, clearError } = useError();
	const [alert, setAlert] = useState(null);
	const [isRegistered, setIsRegistered] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);

	const handleResendVerification = () => {
		const user = auth.currentUser;
		if (user) {
			user.sendEmailVerification()
				.then(() => {
					// Inform the user that the verification email has been resent
					setAlert("Verification email resent. Please check your inbox.");
				})
				.catch((error) => {
					// Handle any errors that occur during the resend
					setError("Error resending verification email: " + error.message);
				});
		}
	};

	const handleGoogleSignIn = () => {
		signInWithGoogle()
		.then((user) => {
			// console.log("User created", user);
			// setError("Logged in!");
		})
		.catch((error) => {
			console.error("Error registering user:", error);
		}	);
	};

	const validatePassword = (pwd) => {
		const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!&%$*]).{8,}$/;
		return regex.test(pwd);
	};

  const handleSubmit = (event) => {
    event.preventDefault();

    // Send event to Google Analytics
    if (window.gtag) {
      window.gtag('event', 'sign_up', {
        event_category: 'engagement',
        event_label: 'Sign Up',
        value: 1
      });
    }

    if (!recaptchaValue) {
      setAlert("Please verify the reCAPTCHA.");
      return;
    }

		setError(null);
		setAlert(null);

		if (!validatePassword(password)) {
			setError("Password should be at least 8 characters long, have at least one uppercase letter, one lowercase letter, one number, and one special character (!&%$*).");
			return;
		}

		if (password !== passwordConfirmation) {
			// console.log('passwords do not match');
			return setError('Passwords do not match');
		}

		registerUser(email, password)
		.then(() => {
			  // console.log("Setting isRegistered to true");
				setIsRegistered(true);
				setAlert("Registration successful. Please check your inbox for a verification email.");
		})
		.catch((error) => {
			// console.error("Error registering user:", error);
			const errorCode = error.message.split('(')[1].split(')')[0];

			switch (errorCode) {
				case "auth/invalid-email":
					setError("The email address is not valid.");
					break;
				case "auth/email-already-in-use":
					setError("Email is already in use by another account.");
					break;
				case "auth/operation-not-allowed":
					setError("Email/password accounts are not enabled.");
					break;
				case "auth/weak-password":
					setError("The password is too weak.");	
					break;
				case "auth/network-request-failed":
					setError("The network connection is lost.");	
					break;
				case "VERIFICATION_SENT":
					setError("Verification email sent. Please check your inbox.");
					break;
				default:
					setError("An unknown error occurred:", error);	
			}
		});
  };

	const clearInfo = () => {
		setAlert(null);
	};

	const clearErrorBox = () => {
		setError(null);
	};

	useEffect(() => {
		if (loading) {
      // maybe trigger a loading screen
			// console.log("Loading");
      return;
    }
    if (user) navigate("/");
  }, [user, loading, navigate]);



  return (
    <>
      <div className='w-full min-h-screen relative'>
        <SEO
          title='Register with Autism In The Media | Advocate for Accurate Representation'
          description='Join our community and be part of the change for authentic autistic representation in film and television. Sign up today and gain deeper insights into movies and shows.'
          keywords='sign up, register, autism, media representation, advocate, community'
          type='website' />
        <img
            className='hidden sm:block absolute inset-0 w-full h-full object-cover z-0'
            src='aitm-background.jpg'
            alt='/'
        />
        <div className='bg-black/60 absolute inset-0 z-10'></div>
        <div className='flex items-start justify-center mt-0 min-h-screen px-4 py-0 md:py-0 lg:py-0 xlg:py-8 z-50 relative'>
            <div className='max-w-[450px] h-[640px] px-10 mx-auto bg-black/75 text-white'>
                <div className='max-w-[320px] mx-auto py-4 md:py-8 lg:py-4'>
                    <h1 className='text-3xl font-bold'>Sign Up</h1>
              <AlertBox type="error" title="Error" message={error} clearAlert={clearErrorBox} />
					    <AlertBox type="info" title="Information" message={alert} color="#3498db" clearAlert={clearInfo} />
              <form
                onSubmit={handleSubmit}
                className='w-full flex flex-col py-4'
              >
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  className='p-3 my-2 bg-gray-700 rounded'
                  value={email}
                  type='email'
                  placeholder='Email'
                  autoComplete='email'
                  required
                />
                <div className="mt-4 flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-white">
                    Required
                  </label>
                  <span className="text-xs text-gray-400">
                    8+ chars, A-Z, a-z, 0-9, !&%$*
                  </span>
                </div>
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  className='p-3 my-2 bg-gray-700 rounded'
                  value={password}
                  type='password'
                  placeholder='Password'
                  autoComplete='current-password'
                  required
                />
                <input
                  type="password"
									className="p-3 my-2 bg-gray-700 rounded"
									value={passwordConfirmation}
									onChange={(e) => setPasswordConfirmation(e.target.value)}
									placeholder="Password Confirmation"
                  autoComplete="password confirmation"
                  required
                />
                <ReCAPTCHA
									sitekey={recaptchaConfig.siteKey}  // Replace with your Site Key
									onChange={value => setRecaptchaValue(value)}
									className='p-0 my-2 rounded'
                />
                <button 
                  className='bg-red-600 py-3 my-6 rounded font-bold'
                  type="submit"
                >
                  Sign Up
                </button>
                <button 
                  type="button"
                  className="bg-red-600 p-3 mb-2 rounded font-bold"
                  onClick={() => handleGoogleSignIn()}
                >
                  Sign in with Google
							</button>
                <p className='py-8'>
                  <span className='text-gray-600'>
                    Already have an AITM account?
                  </span>{' '}
                  <Link to='/signin'>Sign In</Link>
                </p>
                {isRegistered && (
                  <p className="mx-0 my-0">
                  <span className="text-gray-600">
                    Didn't receive the email? 
                  </span>
                  <Link onClick={handleResendVerification} to="#">Resend Verification Email</Link>
                  </p>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
