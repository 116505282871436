import api from '../services/api';
import React, { useEffect, useState } from 'react';
import { requests } from '../Requests';
import { FaCircle } from 'react-icons/fa';
import { Tooltip, Typography } from "@material-tailwind/react";
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import MoviePlayer from '../components/MoviePlayer';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import SEO from '../components/SEO';
import ErrorHandler from '../components/ErrorHandler';
import { BeatLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


function Overview() {
  const { mediatype, id } = useParams();
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [firestoreMedia, setfirestoreMedia] = useState(null);
  const [youtubeId, setYoutubeId] = useState(null);
  const [actors, setActors] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  // const [credits, setCredits] = useState([]);
  const [everything, setEverything] = useState(null);
  const navigate = useNavigate();

  // Close error handler modal
  const handleCloseError = () => {
    setError(null);
  }

  const handleBackClick = () => {
    navigate(-1);  // This will navigate back
  };

  const fetchURL = mediatype === 'movie' ? requests.requestMovie(id) : requests.requestTv(id);

  // useEffect(() => {
  //   console.log("selectedMedia after update:", selectedMedia);
  // }, [selectedMedia]);

  // useEffect(() => {
  //   console.log("selectedVideo after update:", youtubeId);
  // }, [youtubeId]);

  // useEffect(() => {
  //   console.log("Zapme Video after update:", zapme);
  // }, [zapme]);

  // useEffect(() => {
  //   console.log("Actors after update:", actors);
  // }, [actors]);

  // useEffect(() => {
  //   console.log("Everything:", everything);
  // }, [everything]);

  // useEffect(() => {
  //   console.log("Credits after update:", credits);
  // }, [credits]);

  // useEffect(() => {
  //   console.log("Selected Person ID:", selectedPersonId);
  // }, [selectedPersonId]);


  useEffect(() => {
    //const fetchURL = mediaItem.requestSpecific(id);

    api.get(fetchURL).then((response) => {
      setSelectedMedia(response.data);

        setActors(response.data.credits.cast);

        // setCredits(response.data.credits);
        // console.log("Credits:", response.data.credits);
        setEverything(response.data);

        api.get(mediatype === 'movie' ? requests.requestVideo(id) : requests.requestTvVideo(id))
        .then((videoResponse) => {

            // setZapme(videoResponse.data);

            // Use optional chaining to ensure that there are results before processing
            const officialUSVideos = videoResponse.data?.results;
        
            if (officialUSVideos && officialUSVideos.length > 0) {
                setYoutubeId(officialUSVideos[0].key);
            } else {
                setError("No official US video found for the media.");
            }
        })
        .catch(error => {
            if (error.response?.status === 404) {
                setError("No videos found for this media.");
            } else if (error.response) {
                setError(`Error fetching video ID: ${error.response.data.status_message}`);
            } else {
                setError(`Error fetching video ID: ${error.message}`);
            }
        });
        
      
    }).catch(error => {
      setError(`Error fetching media: ${error}`);
    });
  }, [mediatype, id, fetchURL]);

  useEffect(() => {
    if (selectedMedia) {

      // Query Firestore for a document matching the movie's id
      const mediaRef = doc(db, 'media', selectedMedia?.id.toString()); 

      getDoc(mediaRef)
        .then((doc) => {
          if (doc.exists) {
            setfirestoreMedia(doc.data());
          } else {
            setError(prevError => `No matching media in Firestore! ${prevError}`);
          }
          setLoading(false);
      }).catch(errorFromGetDoc => {
        setError(`Error fetching Firestore document: ${errorFromGetDoc}`);
        setLoading(false);
      }); 

    }
  }, [selectedMedia]);

  function formatDate(dateStr) {
    const parts = dateStr.split("-");
    return `${parts[1]}/${parts[2]}/${parts[0]}`;
  }

  const slideLeft = (sliderName) => {
    var slider = document.getElementById(sliderName);
    slider.scrollLeft = slider.scrollLeft - 500;
  };
  const slideRight = (sliderName) => {
    var slider = document.getElementById(sliderName);
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  if (loading) {
    return <BeatLoader loading={loading} />;
  }

  const actorDivWidth = 128;  
  const spacing = 16;  
  const totalWidth = actors.length * actorDivWidth + (actors.length - 1) * spacing;
  const containerWidth = 0.8 * window.innerWidth; // Assuming 80% width based on your w-[80%] class


  const arrowWidth = 42;  // Since you've set the size of the arrow to 40
  const additionalSpacing = 10;  // Additional 10px to give it some space from the last actor div
  const offset = 2 * arrowWidth + additionalSpacing;

  const rightPosition = Math.max(containerWidth - totalWidth - offset, 0);
  const pageTitle = `${selectedMedia?.title || selectedMedia?.name || 'AITM'} - Autistic Representation Details | Autism In The Media`;
  const description = `Get an in-depth look at ${selectedMedia?.title || selectedMedia?.name || 'thi'}'s portrayal of autism. Explore the character's age, gender, ethnicity, and whether they're coded or confirmed as autistic.`
  const keywords = `autism, ${selectedMedia?.title || selectedMedia?.name || 'AITM'}, character details, media representation, autistic character`

  return (
    <>
      {error && <ErrorHandler errorType={error} onClose={handleCloseError} />}
        <div className="pt-4 md:pt-0 lg:pt-0 pb-0 md:pb-0 lg:pb-0">
          <button className="rounded cursor-pointer border text-white border-gray-300 py-2 px-5 ml-0 md:ml-10 lg:ml-10" onClick={handleBackClick}>Back</button>
        </div>
        <div className='h-6 md:h-2 lg:h-0'></div>
        <SEO
          title={pageTitle}
          description={description}
          keywords={keywords}
          type='website' />
        <div className='flex text-white'>
          {
            selectedMedia?.poster_path ? (
              <div className='w-4/10 mt-9 ml-8 md:ml-10 lg:ml-10 mr-8 flex-shrink-0 hidden sm:block'>
                <img 
                  className='w-full'
                  src={`https://image.tmdb.org/t/p/w342${selectedMedia?.poster_path}`} 
                  alt={mediatype === 'movie' ? selectedMedia?.title : selectedMedia?.name}
                />
              </div>
            ) : (
              <div className='pl-0 md:pl-10 lg:pl-10 w-6/10 mt-0 md:mt-0'>
                <p></p>
              </div>
            )
          }
          <div className='w-6/10 mt-0 md:mt-16'>
            <div className='text-2xl md:text-4xl'>{mediatype === 'movie' ? selectedMedia?.title : selectedMedia?.name} ({mediatype === 'movie' ? (selectedMedia?.release_date ? formatDate(selectedMedia.release_date) : 'N/A').split("/")[2] : (selectedMedia?.first_air_date ? formatDate(selectedMedia.first_air_date) : 'N/A').split("/")[2]})</div>
            <div className='flex items-center space-x-2 text-gray-400 text-sm md:text-base pt-2'>
              {
              selectedMedia?.release_date ? (
                <>
                  <span> Released: {mediatype === 'movie' ? (selectedMedia?.release_date ? formatDate(selectedMedia.release_date) : 'N/A') : (selectedMedia?.first_air_date ? formatDate(selectedMedia.first_air_date) : 'N/A')}</span>
                  <FaCircle size={6} />
                </>
                ) : null
              }
              {
              selectedMedia?.genres ? (
                <>
                  <span> Genres: {selectedMedia?.genres?.map(genre => genre.name).join(', ')}</span>
                </>
                ) : null
              } 
              {
                mediatype === 'movie' && selectedMedia?.runtime ? (
                  <>
                      <FaCircle size={6} />
                      <span> Runtime: {selectedMedia?.runtime} minutes</span>
                  </>
                ) : null 
              }
            </div>
            {selectedMedia && selectedMedia.created_by && selectedMedia.created_by.length > 0 &&
              <>
              <div className='text-sm md:text-base text-gray-400'>
                  <span>Created by: {selectedMedia?.created_by?.map(creator => creator.name).join(', ')}</span>
              </div>
              </>
            }
            {selectedMedia && selectedMedia.number_of_seasons && 
              <>
                  <div className='text-sm md:text-base text-gray-400'>
                    <span>Seasons: {selectedMedia?.number_of_seasons}</span>
                  </div>
              </>
            }
            {selectedMedia && selectedMedia.homepage && 
              <>
                  <div className='text-sm md:text-base text-gray-400 flex flex-nowrap'>
                      <span>Visit Homepage: </span>
                      <a href={selectedMedia?.homepage} rel="noreferrer" target='_blank' title="Visit Homepage">
                          <img src="/social-link.svg" alt="Visit Homepage" className="h-6 w-auto ml-2" />
                      </a>
                  </div>
              </>
            }
            {everything && everything.credits.crew && everything.credits.crew.filter(member => member.job === "Director").length > 0 &&
              <>
                <div className='text-sm md:text-base text-gray-400'>
                    <span>Directed by: {
                      everything.credits.crew
                        .filter(member => member.job === "Director")
                        .map(director => director.name)
                        .join(', ')
                    }</span>
                </div>
              </>
            }

            <div className='mt-4 mb-6'>
              {youtubeId && <MoviePlayer videoId={youtubeId} mediaItem={selectedMedia} />}
            </div>
            <div className='mt-4 text-1xl md:text-2xl'>Overview</div>
            <div className='md:w-[60%] w-[90%] mt-4 mb-4 md:mb-4 lg:mb-6 text-sm md:text-base mr-0 md:mr-20'>{selectedMedia?.overview}</div>
            <div className='md:w-[60%]  mr-20'>
            {firestoreMedia?.characterName && (
              <table className="min-w-full ">
                <tbody>
                    <tr>
                        <td className="pr-2 md:pr-0 lg:pr-0 pb-4 md:pb-4 lg:pb-4 text-sm font-medium">
                            <p className="text-yellow-500"><strong className="text-yellow">Character Name</strong></p>
                            <p className="flex items-center">
                              <span>{firestoreMedia?.characterName}</span>
                              {
                                (firestoreMedia?.characterDescription !== undefined && firestoreMedia?.characterDescription !== null && firestoreMedia?.characterDescription !== '') && (
                                  <Tooltip
                                    content={
                                      <div className="w-80">
                                        <Typography className="text-purple-400 px-6 pt-4 pb-1 font-medium text-lg">
                                          Character Description
                                        </Typography>
                                        <Typography
                                          variant="small"
                                          color="white"
                                          className="px-6 pt-1 pb-4 font-normal opacity-80"
                                        >
                                          {firestoreMedia?.characterDescription}
                                        </Typography>
                                      </div>
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      className="h-5 w-5 cursor-pointer text-purple-400 ml-2" 
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                      />
                                    </svg>
                                  </Tooltip>
                                )
                              } 
                            </p>
                        </td>
                        <td className="pr-2 md:pr-0 lg:pr-0 pb-4 md:pb-4 lg:pb-4 text-sm whitespace-nowrap">
                            <p className="text-yellow-500"><strong>Character Type</strong></p>
                            <p className="flex items-center"> {/* Use flexbox to align items center */}
                              <span>{firestoreMedia?.characterType}</span>
                              {firestoreMedia?.characterType.includes("Autistic-Coded") && (
                                <Tooltip
                                  content={
                                    <div className="w-80">
                                      <Typography className="text-purple-400 px-6 pt-4 pb-1 font-medium text-lg">
                                        Autistic-Coded
                                      </Typography>
                                      <Typography
                                        variant="small"
                                        color="white"
                                        className="px-6 pt-1 pb-4 font-normal opacity-80"
                                      >
                                        Writing or performing a character with several traits common to autistic people
                                        but without confirming it or explicitly discussing it.
                                      </Typography>
                                    </div>
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    className="h-5 w-5 cursor-pointer text-purple-400 ml-2" 
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                    />
                                  </svg>
                                </Tooltip>
                              )}
                            </p>
                        </td>
                        <td className="pb-4 md:pb-4 lg:pb-4 text-sm whitespace-nowrap">
                            <p className="text-yellow-500"><strong>Character Age</strong></p>
                            <p>{firestoreMedia?.characterAge}</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="pr-2 md:pr-0 lg:pr-0 text-sm whitespace-nowrap">
                            <p className="text-yellow-500"><strong>Character Gender</strong></p>
                            <p>{firestoreMedia?.characterGender}</p>
                        </td>
                        <td className="pr-2 md:pr-0 lg:pr-0 text-sm whitespace-nowrap">
                            <p className="text-yellow-500"><strong>Character Ethnicity</strong></p>
                            <p>{firestoreMedia?.characterEthnicity}</p>
                        </td>
                        <td className="text-sm whitespace-nowrap">
                            <p className="text-yellow-500"><strong>Autistic Actor?</strong></p>
                            <p>{firestoreMedia?.actorAutistic ? 'Yes' : 'No'}</p>
                        </td>
                    </tr>
                </tbody>
              </table>
              )}
            </div>
          </div>
        </div>


        {everything && everything.credits.crew && Object.keys(everything.credits.crew.filter(member => member.job === "Director")).length && (
          <>
          <div className='mt-9 ml-10 md:w-[60%] w-[90%] mr-20 text-white text-2xl'>
            {everything.credits.crew.filter(member => member.job === "Director").length > 1 ? "Directors" : "Director"}
          </div>

          
        <div className='mt-9 ml-10 w-[70%] lg:w-[75%] md:w-[80%]sm:w-[90%] mr-20 relative flex items-center group'>
          <div id="slideDirectors" className="flex overflow-x-scroll space-x-4 scrollbar-hide scroll-smooth relative">
              {everything && everything.credits?.crew && everything.credits.crew.filter(member => member.job === "Director").length > 0 && everything.credits.crew.filter(member => member.job === "Director").map((director, index) => (
                  <div key={index} className="flex-none w-32 bg-white p-0 rounded shadow-lg">
                    <Link
                      to={`/person/${director.id}`}
                      className="flex-none w-32 bg-white p-0 rounded shadow-lg"
                    >
                      {director.profile_path ? (
                          <img className='rounded' src={requests.requestHeadShot(director.profile_path)} alt={director.name} />
                      ) : director.gender === 2 ? (
                          <img className='rounded' src="/user-grey.svg" alt="Male Placeholder" />
                      ) : director.gender === 1 ? (
                          <img className='rounded' src="/user-female-grey.svg" alt="Female Placeholder" />
                      ) : (
                          <img className='rounded' src="/user-grey.svg" alt="Default Placeholder" />
                      )}
                      <div className="mt-2 break-all w-full overflow-hidden">
                          <p className="pl-2 text-sm font-semibold truncate">{director.name}</p>
                          <p className="pl-2 pb-2 text-gray-600 text-xs truncate">{director.character}</p>
                      </div>
                    </Link>
                  </div>
              ))}
          </div>
        </div>
        </>
)}

        {Object.keys(actors).length && (
          <div className='mt-9 ml-10 md:w-[60%] w-[90%] mr-20 text-white text-2xl'>Cast</div>
        )}
        <div className='mt-9 ml-10 w-[70%] lg:w-[75%] md:w-[80%]sm:w-[90%] mr-20 relative flex items-center group'>
          <MdChevronLeft
            onClick={() => slideLeft('slideActors')}
            className='bg-white left-0 rounded-full absolute space-x-4 opacity-50 hover:opacity-100 cursor-pointer z-10 hidden group-hover:block'
            size={40}
          />
        <div id="slideActors" className="flex overflow-x-scroll space-x-4 scrollbar-hide scroll-smooth relative">
            {actors && actors.length > 0 && actors.map((actor, index) => (
                <Link 
                  key={index} 
                  to={`/person/${actor.id}`}
                  className="flex-none w-32 bg-white p-0 rounded shadow-lg"
                >
                    {actor.profile_path ? (
                        <img className='rounded' src={requests.requestHeadShot(actor.profile_path)} alt={actor.name} />
                    ) : actor.gender === 2 ? (
                        <img className='rounded' src="/user-grey.svg" alt="Male Placeholder" />
                    ) : actor.gender === 1 ? (
                        <img className='rounded' src="/user-female-grey.svg" alt="Female Placeholder" />
                    ) : (
                        <img className='rounded' src="/user-grey.svg" alt="Default Placeholder" />
                    )}
                    <div className="mt-2 break-all w-full overflow-hidden">
                        <p className="pl-2 text-sm font-semibold truncate">{actor.name}</p>
                        <p className="pl-2 pb-2 text-gray-600 text-xs truncate">{actor.character}</p>
                    </div>
                </Link>
            ))}
        </div>

          <MdChevronRight
            onClick={() => slideRight('slideActors')}
            style={{ right: `${rightPosition}px` }}
            className='bg-white right-0 rounded-full absolute opacity-50 hover:opacity-100 cursor-pointer z-10 hidden group-hover:block'
            size={40}
          />
        </div>
    </>
  );
}

export default Overview;
