// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { 
	GoogleAuthProvider,
  EmailAuthProvider,
	getAuth,
	signInWithPopup,
	signInWithEmailAndPassword,
  sendEmailVerification, 
	createUserWithEmailAndPassword, 
	sendPasswordResetEmail,
  updateEmail,
  updatePassword,
  reauthenticateWithCredential,
	signOut,
  fetchSignInMethodsForEmail, 
} from 'firebase/auth';
import {
	getFirestore,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
} from 'firebase/firestore';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

// import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
// import { getAuth, connectAuthEmulator } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  appId: process.env.REACT_APP_APP_ID
};

const recaptchaConfig = {
  siteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
};

const googleAnalyticsConfig = {
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const googleProvider = new GoogleAuthProvider();
export { recaptchaConfig };
export { googleAnalyticsConfig };

// Initialize Firebase App Check with reCAPTCHA v3
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN;
}

export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY),
  isTokenAutoRefreshEnabled: true,
});

export const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    
    // Check if the user already exists in the database
    const userDocRef = doc(db, "users", user.uid);
    const userDocSnapshot = await getDoc(userDocRef);
    if (!userDocSnapshot.exists()) {
      // Use setDoc to specify the document ID
      await setDoc(userDocRef, {
        uid: user.uid,
        authProvider: "google",
        email: user.email,
        savedShows: [],
      });
    }
  } catch (err) {
    console.error(err);
    throw new Error(err.message);
  }
};

export const registerUser = async (email, password) => {
  // console.log("registerUser")
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  const uid = userCredential.user.uid;

  sendEmailVerification(userCredential.user)
  .then(() => {
    // Use setDoc instead of addDoc to specify the document ID
    return setDoc(doc(db, "users", uid), {
      uid: uid,
      authProvider: "local",
      email,
      savedShows: [],
    });
  })
  .catch((error) => {
    console.error("Error sending email verification", error);
    // throw new Error(error.message);
  });
    
  return userCredential.user;
}

export const signInUser = async (email, password) => {
	try {
			const userCredential = await signInWithEmailAndPassword(auth, email, password);
			return userCredential.user;
	} catch (err) {
		console.error(err);
		throw new Error(err.message);
	}
}

export const signOutUser = async () => {
	try {
			await signOut(auth);
			return true;
	} catch (err) {
		console.error(err);
		throw new Error(err.message);
	}
}

export const reauthenticate = async (currentPassword) => {
  const user = auth.currentUser;
  const credential = EmailAuthProvider.credential(user.email, currentPassword);
  return await reauthenticateWithCredential(user, credential);
}

export const updateUserEmail = async (newEmail, currentPassword) => {
  try {
    await reauthenticate(currentPassword);
    const user = auth.currentUser;
    await updateEmail(user, newEmail);
  } catch (err) {
    console.error(err);
    throw new Error(err.message);
  }
};

export const updateUserPassword = async (newPassword, currentPassword) => {
  try {
    await reauthenticate(currentPassword);
    const user = auth.currentUser;
    await updatePassword(user, newPassword);
  } catch (err) {
    console.error(err);
    throw new Error(err.message);
  }
};

export const sendPasswordReset = async (email) => {
  try {
    const methods = await fetchSignInMethodsForEmail(auth, email);
    if (!methods.length) {
      throw new Error("This email is not associated with any account. (auth/email-not-found)");
    }
    await sendPasswordResetEmail(auth, email);
    return true;
  } catch (err) {
    console.error(err);
    throw new Error(err.message);
  }
};

export const deleteAccount = async () => {
  const user = auth.currentUser;

  // Step 1: Delete from users collection
  const userDocRef = doc(db, "users", user.uid);

  return deleteDoc(userDocRef)
    .then(() => {
      // Step 2: Delete from Firebase Authentication
      return user.delete();
    })
    .then(() => {
      // console.log("Account deleted successfully.");
      return true;
    })
    .catch(err => {
      console.error("Error deleting account:", err);
      throw new Error(err.message);
    });
};

// Connect to Firestore emulator if running on localhost
// if (window.location.hostname === "localhost") {
//   useFirestoreEmulator(db, "localhost", 8080);
// }

// For development environment, connect to emulators
// if (process.env.NODE_ENV === 'development') {
//   connectFirestoreEmulator(db, "localhost", 8080);
//   connectAuthEmulator(auth, "http://localhost:9099");
// }